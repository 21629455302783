import {ReactNode, useEffect} from 'react'
import {Center, Text, Box} from 'ui/core'
import {PUBLIC_PAGES} from 'helpers/constants'
import {useAuth, useCheckIsAdmin} from '@resellam/auth'

const Cover = ({children}: {children: ReactNode}) => (
  <Box sx={(theme) => theme.fn.cover()}>
    <Center sx={{width: '100vw', height: '100vh', position: 'relative'}}>{children}</Center>
  </Box>
)

export interface AuthorizationProps {
  children: ReactNode
}

const Authorization = ({children}: AuthorizationProps) => {
  const {user} = useAuth()
  const [checkIsAdmin, checkIsAdminState] = useCheckIsAdmin()

  const pathname = typeof window === 'undefined' ? null : window.location.pathname
  const isPublic = () =>
    pathname && PUBLIC_PAGES.some((page) => pathname?.split('/')[1] === page.split('/')[1])

  useEffect(() => {
    if (!isPublic() && user) checkIsAdmin()
  }, [pathname, user])

  if (
    user &&
    !isPublic() &&
    (checkIsAdminState.isSuccess || checkIsAdminState.isError) &&
    !checkIsAdminState.value
  )
    return (
      <Cover>
        <Text size="xl" sx={{zIndex: 2}}>
          You are not authorized
        </Text>
      </Cover>
    )

  return <>{children}</>
}

export default Authorization

import {AppProps} from 'next/app'
import Head from 'next/head'
import Authorization from 'components/Authorization'
import AuthProvider from 'components/AuthProvider'
import {CoreProvider} from 'core/components'
import AppLayout from 'components/AppLayout'
import type {ReactElement, ReactNode} from 'react'
import type {NextPage} from 'next'
import {SWRConfig} from 'swr'
import {logger} from '@resellam/logger'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({Component, pageProps}: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => <AppLayout>{page}</AppLayout>)

  return (
    <>
      <Head>
        <title>Admin</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>

      <SWRConfig value={{onError: (err, key) => logger.error(err, key)}}>
        <AuthProvider>
          <CoreProvider colorScheme="dark">
            <Authorization>{getLayout(<Component {...pageProps} />)}</Authorization>
          </CoreProvider>
        </AuthProvider>
      </SWRConfig>
    </>
  )
}

export default App

import {Menu, Divider, Icon} from 'ui/core'
import {useAuth} from '@resellam/auth'
import {RightMenu as CoreRightMenu} from 'core/components'
import Link from 'next/link'

const RightMenu = () => {
  const {signout} = useAuth()

  return (
    <CoreRightMenu>
      <Menu.Item href="/orders" icon={<Icon name="shoppingBag" color="gray" />} component={Link}>
        Orders
      </Menu.Item>
      <Menu.Item
        href="/sell-requests"
        icon={<Icon name="layoutList" color="gray" />}
        component={Link}
      >
        Sell requests
      </Menu.Item>
      <Menu.Item href="/fields" icon={<Icon name="braces" color="gray" />} component={Link}>
        Fields
      </Menu.Item>
      <Divider />
      <Menu.Item
        href="/settings/profile"
        component={Link}
        icon={<Icon name="settings" color="gray" />}
      >
        Settings
      </Menu.Item>
      <Menu.Item icon={<Icon name="logout" color="gray" />} onClick={signout}>
        Log out
      </Menu.Item>
    </CoreRightMenu>
  )
}

export default RightMenu

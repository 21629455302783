import {AppShellProps} from 'ui/core'
import {AppHeader, AppFooter, AppLayout as CoreAppLayout, BrandLink} from 'core/components'
import RightMenu from './RightMenu'

export interface AppLayoutProps extends AppShellProps {}

const AppLayout = ({children}: AppLayoutProps) => (
  <CoreAppLayout
    header={<AppHeader leftSection={<BrandLink title="Admin" />} rightSection={<RightMenu />} />}
    footer={<AppFooter />}
  >
    {children}
  </CoreAppLayout>
)

export default AppLayout
